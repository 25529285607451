import {Content, ListItem, PageTitle, Paragraph, Section, SectionTitle, Spacer, UList} from "../../styles/content";
import {MEDIA_SIZES, useMediaSizeContext} from "../../contexts/media-size";
import {Helmet} from "react-helmet-async";
import {FooterImage} from "../../components/footer-image";

import MeImageAvif from "../../images/tanja-blondeel-cv.avif";
import MeImageWebp from "../../images/tanja-blondeel-cv.webp";
import MeImageJpg from "../../images/tanja-blondeel-cv.jpg";

import FooterImageAvif2160 from "../../images/footer-about-me-2160.avif";
import FooterImageAvif1440 from "../../images/footer-about-me-1440.avif";
import FooterImageAvif720 from "../../images/footer-about-me-720.avif";
import FooterImageAvif480 from "../../images/footer-about-me-480.avif";
import FooterImageWebp2160 from "../../images/footer-about-me-2160.webp";
import FooterImageWebp1440 from "../../images/footer-about-me-1440.webp";
import FooterImageWebp720 from "../../images/footer-about-me-720.webp";
import FooterImageWebp480 from "../../images/footer-about-me-480.webp";
import FooterImageJpg2160 from "../../images/footer-about-me-2160.jpg";
import FooterImageJpg1440 from "../../images/footer-about-me-1440.jpg";
import FooterImageJpg720 from "../../images/footer-about-me-720.jpg";
import FooterImageJpg480 from "../../images/footer-about-me-480.jpg";
import {MePicture, MePictureMobile} from "./content";

export function AboutMe() {
    const {mediaSize} = useMediaSizeContext();
    const isSmallSize = [MEDIA_SIZES.MiniPhone, MEDIA_SIZES.Phone, MEDIA_SIZES.TabletPortrait].includes(mediaSize);
    const isPhoneSize = [MEDIA_SIZES.MiniPhone, MEDIA_SIZES.Phone].includes(mediaSize);
    return (
        <Content>
            <Helmet>
                <title>De Spiegel - Over mezelf</title>
                <meta name="description"
                      content="Lees hier meer over wie ik ben en welke rol ik voor jou kan betekenen. Je vindt hier ook meer informatie over mijn ervaring en opleiding."/>
            </Helmet>
            <PageTitle>
                Over mezelf
            </PageTitle>
            <Section>
                {!isSmallSize &&
                    <MePicture>
                        <source srcSet={MeImageAvif} type="image/avif"/>
                        <source srcSet={MeImageWebp} type="image/webp"/>
                        <img src={MeImageJpg} alt="Tanja Blondeel" loading="lazy" decoding="async"/>
                    </MePicture>
                }
                <Paragraph mb={2} ta={isSmallSize ? "center" : "left"}>
                    Je mentaal welzijn op peil houden, kan soms uitdagend zijn. Steeds meer maakt ook mentale gezondheid
                    deel uit van ‘zorgen voor jezelf’. Begeleiding of ondersteuning kan dan de ruggensteun zijn die
                    nodig is om er terug tegenaan te gaan! Dit is waar ik in beeld kom. Ik ben altijd al erg
                    gepassioneerd geweest door het helpen van mensen. Als klinisch- en gezondheidspsycholoog bied ik
                    psychologische begeleiding bij uiteenlopende thema’s.
                </Paragraph>
                <Paragraph ta={isSmallSize ? "center" : "left"}>
                    Vaak speelt ook het werkleven een g:rote rol in welzijn. Tijdens mijn aanvullende opleiding in
                    Arbeids- en Organisatiepsychologie kon ik ook dit interesseveld verkennen.Samen gaan we op zoek naar
                    welke aanpak het beste bij jou past!
                </Paragraph>
                {isSmallSize &&
                    <MePictureMobile>
                        <source srcSet={MeImageAvif} type="image/avif"/>
                        <source srcSet={MeImageWebp} type="image/webp"/>
                        <img src={MeImageJpg} alt="Tanja Blondeel" loading="lazy" decoding="async"/>
                    </MePictureMobile>
                }
            </Section>
            <Spacer/>
            <Section>
                <SectionTitle>
                    Als psycholoog ben ik
                </SectionTitle>
                <UList w={isPhoneSize ? "100%" : isSmallSize ? "75%" : "66%"} mb={2} mHor="auto" pr={1}>
                    <ListItem>Erkend door de overheid (visumnummer 314192)</ListItem>
                    <ListItem>Erkend Klinisch Psycholoog door Vlaams agentschap Zorg en Gezondheid</ListItem>
                    <ListItem>Erkend door de psychologen-commissie (erkenningsnummer 962131290)</ListItem>
                </UList>
                <Paragraph mb={2}>
                    Hiermee ben ik gebonden aan de deontologische code, waar onder andere het beroepsgeheim onder valt.
                </Paragraph>
                <Paragraph>
                    <iframe title="compsy" frameBorder="0" width="250" height="150"
                            src="https://www.compsy.be/nl/psychologist/embed/31290"/>
                </Paragraph>
            </Section>
            <Spacer/>
            <Section>
                <SectionTitle>
                    Ervaring &amp; Opleiding
                </SectionTitle>
                <Paragraph mb={2}>
                    Aan de Katholieke Universiteit Leuven heb ik de masteropleiding Klinische en Gezondheidspsychologie
                    optie volwassenen volbracht. Hierna heb ik bijkomend de master Arbeids- en Organisatiepsychologie
                    afgerond.
                </Paragraph>
                <Paragraph>
                    Tijdens mijn klinische opleiding heb ik onder andere stage gelopen in het Jessa ziekenhuis en het
                    Ziekenhuis Oost-Limburg in een liaisonfunctie met de verpleegafdelingen (oncologie,
                    gastro-enterologie, …). Ook heb ik in het Obesitascentrum samengewerkt in het multidisciplinaire
                    team.
                    Globis Consulting is het bedrijf waar ik mijn stage Arbeids- en Organisatiepsychologie heb
                    volbracht. Hier focuste ik op opleidingen geven rond leidinggeven en communicatie. Ook voerde ik
                    samen met mijn collega's assessments uit.
                </Paragraph>
            </Section>
            <Spacer/>
            <FooterImage avifImages={{
                xl: FooterImageAvif2160,
                lg: FooterImageAvif1440,
                sm: FooterImageAvif720,
                xs: FooterImageAvif480
            }}
                         webpImages={{
                             xl: FooterImageWebp2160,
                             lg: FooterImageWebp1440,
                             sm: FooterImageWebp720,
                             xs: FooterImageWebp480
                         }}
                         jpgImages={{
                             xl: FooterImageJpg2160,
                             lg: FooterImageJpg1440,
                             sm: FooterImageJpg720,
                             xs: FooterImageJpg480
                         }}
            />
        </Content>
    )
}
