import { useEffect, useMemo, useRef, useState } from 'react'
import {debounce} from "../utils/debounce";

const getCurrentSize = () => {
    if (typeof window === 'undefined') {
        return 0
    }

    return (
        window.innerWidth
    )
}

export const useResize = (wait = 0) => {
    const defaultSize = useMemo(() => getCurrentSize(), []);
    const previousScreenSize = useRef(defaultSize);
    const [screenSize, setScreenSize] = useState(defaultSize);

    useEffect(() => {
        const handleDocumentResize = () => {
            const currentScreenSize = getCurrentSize()
            setScreenSize(currentScreenSize)
            previousScreenSize.current = currentScreenSize
        }

        const handleDocumentResizeDebounced = debounce(handleDocumentResize, wait)
        window.addEventListener('resize', handleDocumentResizeDebounced)

        return () => {
            window.removeEventListener('resize', handleDocumentResizeDebounced)
        }
    }, [wait]);

    return {
        screenSize: screenSize,
        previousScreenSize: previousScreenSize.current,
        time: wait,
    }
}
