import {NavLink, Outlet, useLocation} from "react-router-dom";
import {useScrollContext} from "../contexts/scroll";
import {MEDIA_SIZES, useMediaSizeContext} from "../contexts/media-size";
import {
    AppHeader,
    AppLogo,
    FixedAppLogo,
    FixedHeader,
    FixedNavigation,
    FixedTitles,
    FixedWrapper,
    Footer, LayoutContainer,
    LinkSpacer,
    MobileNavButton,
    MobileNavigation,
    MobileNavigationWrapper,
    Wrapper
} from "./style";
import {ReactComponent as NavMenuIcon} from "../icons/navigation-menu-24px.svg";
import {ReactComponent as NavMenuIconOpen} from "../icons/navigation-menu_open-24px.svg";
import {useEffect, useState} from "react";

export function Layout() {
    const { pathname } = useLocation();
    const [isHome, setIsHome] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);

    const HEADER_HEIGHTS = {
        [MEDIA_SIZES.MiniPhone]: 290,
        [MEDIA_SIZES.Phone]: 290,
        [MEDIA_SIZES.TabletPortrait]: 220,
        [MEDIA_SIZES.TabletLandscape]: 250,
        [MEDIA_SIZES.Desktop]: 300,
        [MEDIA_SIZES.BigDesktop]: 350,
    };
    const {scrollTop} = useScrollContext();
    const {mediaSize} = useMediaSizeContext();

    useEffect(() => {
        setIsHome(window.location.pathname === "/");
    }, [pathname]);

    const handleLinkClick = () => {
        setShowMobileNav(false);
    }

    const renderTitles = () => <>
        <h1>
            Psychologiepraktijk
        </h1>
        <h2>
            De Spiegel
        </h2>
    </>;

    const getNavlinkClassName = ({isActive}) => (isActive ? 'active' : 'inactive')

    const renderNavigation = (NavigationElement) =>
        <NavigationElement>
            <NavLink onClick={handleLinkClick} to="/" className={getNavlinkClassName}>Welkom</NavLink>
            <LinkSpacer/>
            <NavLink onClick={handleLinkClick} to="/doelgroep" className={getNavlinkClassName}>Doelgroep</NavLink>
            <LinkSpacer/>
            <NavLink onClick={handleLinkClick} to="/aanpak" className={getNavlinkClassName}>Aanpak</NavLink>
            <LinkSpacer/>
            <NavLink onClick={handleLinkClick} to="/over-mezelf" className={getNavlinkClassName}>Over mezelf</NavLink>
            <LinkSpacer/>
            <NavLink onClick={handleLinkClick} to="/tarieven" className={getNavlinkClassName}>Tarieven</NavLink>
            <LinkSpacer/>
            <NavLink onClick={handleLinkClick} to="/maak-een-afspraak" className={getNavlinkClassName}>Maak een
                afspraak</NavLink>
            <LinkSpacer/>
            <NavLink onClick={handleLinkClick} to="/contact" className={getNavlinkClassName}>Contact</NavLink>
        </NavigationElement>;

    const isHomePage = window.location.pathname === "/";
    const showMobileNavButton = [MEDIA_SIZES.MiniPhone, MEDIA_SIZES.Phone, MEDIA_SIZES.TabletPortrait].includes(mediaSize);
    const isScrollPassed = scrollTop > HEADER_HEIGHTS[mediaSize];

    const handleMobileNavButtonClicked = () => {
        setShowMobileNav(!showMobileNav);
    }
    const fixedHeaderClassNames = [];
    if(!isHomePage || isScrollPassed) {
        fixedHeaderClassNames.push('fixed');
    }
    if(!isHomePage) {
        fixedHeaderClassNames.push('not-home');
    }
    return (
        <LayoutContainer className={isHome ? "home" : "not-home"}>
            <FixedHeader
                className={fixedHeaderClassNames.join(" ")}>
                <FixedWrapper>
                    {(!isHomePage || isScrollPassed) && <>
                        <FixedAppLogo/>
                        <FixedTitles>
                            {renderTitles()}
                        </FixedTitles>
                    </>
                    }
                </FixedWrapper>
                {!showMobileNavButton && renderNavigation(FixedNavigation)}
                {showMobileNavButton && <>
                    <MobileNavButton onClick={handleMobileNavButtonClicked}>
                        {showMobileNav ? <NavMenuIconOpen/> : <NavMenuIcon/>}
                    </MobileNavButton>
                    <MobileNavigationWrapper className={showMobileNav ? 'visible' : ''}>
                        {renderNavigation(MobileNavigation)}
                    </MobileNavigationWrapper>
                </>
                }
            </FixedHeader>
            {isHomePage &&
                <AppHeader>
                    <Wrapper>
                        <AppLogo/>
                        {renderTitles()}
                    </Wrapper>
                </AppHeader>
            }

            <Outlet/>
            <Footer>
                &copy; {new Date(Date.now()).getFullYear()} | de-spiegel.be
            </Footer>
        </LayoutContainer>
    )
}
