import {Content, PageTitle, Paragraph, Section, Spacer,} from "../../styles/content";
import {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {FooterImage} from "../../components/footer-image";

import FooterImageAvif2160 from "../../images/footer-appointment-2160.avif";
import FooterImageAvif1440 from "../../images/footer-appointment-1440.avif";
import FooterImageAvif720 from "../../images/footer-appointment-720.avif";
import FooterImageAvif480 from "../../images/footer-appointment-480.avif";
import FooterImageWebp2160 from "../../images/footer-appointment-2160.webp";
import FooterImageWebp1440 from "../../images/footer-appointment-1440.webp";
import FooterImageWebp720 from "../../images/footer-appointment-720.webp";
import FooterImageWebp480 from "../../images/footer-appointment-480.webp";
import FooterImageJpg2160 from "../../images/footer-appointment-2160.jpg";
import FooterImageJpg1440 from "../../images/footer-appointment-1440.jpg";
import FooterImageJpg720 from "../../images/footer-appointment-720.jpg";
import FooterImageJpg480 from "../../images/footer-appointment-480.jpg";

export function Appointments() {
    useEffect(() => {
        window.addEventListener && window.addEventListener("message",
            function (event) {
            console.log("Message event received", event);
                if (event.origin === "https://psycholoogtanja.youcanbook.me") {
                    document.getElementById("ycbmiframepsycholoogtanja").style.height = event.data + "px";
                }
            }, false);
    });

    return (
        <Content>
            <Helmet>
                <title>De Spiegel - Maak een afspraak</title>
                <meta name="description"
                      content="Hier kun je een afspraak maken bij psychologiepraktijk De Spiegel. Kies een vrij tijdslot en bevestig de reservering."/>
            </Helmet>
            <PageTitle>
                Maak een afspraak
            </PageTitle>
            <Section>
                <iframe title="you can book me" src="https://psycholoogtanja.youcanbook.me/?noframe=true&skipHeaderFooter=true"
                        style={{
                            backgroundColor: 'transparent !important',
                            border: 0
                        }}
                        id="ycbmiframepsycholoogtanja" width="100%" height="1000px" frameBorder="0" />
            </Section>
            <Section>
                <Paragraph mb={2}>
                    Verhinderd voor een afspraak? Dit is natuurlijk heel vervelend voor iedereen. Daarom zou ik willen
                    vragen bij belet mij eventjes een seintje te geven aangezien ik voor iedere afspraak ook de nodige
                    voorbereidingen maak.
                </Paragraph>
                <Paragraph mb={2}>
                    Wil je door omstandigheden toch je afspraak verplaatsen? Graag dan een seintje ten minste 24 uur op
                    voorhand via sms of mail.
                </Paragraph>
                <Paragraph>
                    Iedereen krijgt bij het niet kunnen nakomen van een afspraak een joker, maar bij een tweede keer op
                    het laatste moment annuleren of niet komen opdagen wordt de volledige sessie aangerekend.
                </Paragraph>
            </Section>
            <Spacer/>
            <FooterImage avifImages={{xl: FooterImageAvif2160, lg: FooterImageAvif1440, sm: FooterImageAvif720, xs: FooterImageAvif480}}
                         webpImages={{xl: FooterImageWebp2160, lg: FooterImageWebp1440, sm: FooterImageWebp720, xs: FooterImageWebp480}}
                         jpgImages={{xl: FooterImageJpg2160, lg: FooterImageJpg1440, sm: FooterImageJpg720, xs: FooterImageJpg480}}
            />
        </Content>
    )
}
