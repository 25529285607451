import {Anchor, Content, PageTitle, Paragraph, Section, SectionTitle, Spacer} from "../../styles/content";
import {Helmet} from "react-helmet-async";
import {FooterImage} from "../../components/footer-image";

import FooterImageAvif2160 from "../../images/footer-rates-2160.avif";
import FooterImageAvif1440 from "../../images/footer-rates-1440.avif";
import FooterImageAvif720 from "../../images/footer-rates-720.avif";
import FooterImageAvif480 from "../../images/footer-rates-480.avif";
import FooterImageWebp2160 from "../../images/footer-rates-2160.webp";
import FooterImageWebp1440 from "../../images/footer-rates-1440.webp";
import FooterImageWebp720 from "../../images/footer-rates-720.webp";
import FooterImageWebp480 from "../../images/footer-rates-480.webp";
import FooterImageJpg2160 from "../../images/footer-rates-2160.jpg";
import FooterImageJpg1440 from "../../images/footer-rates-1440.jpg";
import FooterImageJpg720 from "../../images/footer-rates-720.jpg";
import FooterImageJpg480 from "../../images/footer-rates-480.jpg";

export function Rates() {
    return (
        <Content>
            <Helmet>
                <title>De Spiegel - Tarieven</title>
                <meta name="description"
                      content="Wij communiceren zeer open over onze tarieven, je kunt ze daarom ook makkelijk
                      terugvinden op deze pagina. Wist je dat de meeste mutualiteiten zelfs een terugbetaling voorzien
                      bij het consulteren van een klinisch psycholoog?"/>
            </Helmet>
            <PageTitle>
                Tarieven
            </PageTitle>
            <Section>
                <Paragraph mb={1}>
                    Gewoonlijk duurt een sessie 60 minuten en bedraagt de kost 60 euro. Afhankelijk van de begeleiding
                    die we samen kiezen, zijn ook sessies van 30 minuten (€30) mogelijk.
                    Je kan in de praktijk ofwel cash betalen ofwel via mobiel betalen (Payconiq, bankapp), ... .
                </Paragraph>
            </Section>
            <Spacer />
            <Section>
                <SectionTitle>
                    Terugbetaling
                </SectionTitle>
                <Paragraph>
                    De meeste mutualiteiten voorzien een (gedeeltelijke) terugbetaling bij het consulteren van een
                    klinisch psycholoog. Het bedrag per sessie en aantal sessies per jaar is verschillend per
                    mutualiteit. Voor meer informatie en verduidelijking van de voorwaarden kan je best jouw mutualiteit
                    contacteren of kijken op <Anchor href="https://www.vind-een-psycholoog.be/info/terugbetaling-psychotherapie.html" target="_blank">deze website</Anchor>.
                </Paragraph>
            </Section>
            <Spacer/>
            <FooterImage avifImages={{xl: FooterImageAvif2160, lg: FooterImageAvif1440, sm: FooterImageAvif720, xs: FooterImageAvif480}}
                         webpImages={{xl: FooterImageWebp2160, lg: FooterImageWebp1440, sm: FooterImageWebp720, xs: FooterImageWebp480}}
                         jpgImages={{xl: FooterImageJpg2160, lg: FooterImageJpg1440, sm: FooterImageJpg720, xs: FooterImageJpg480}}
            />
        </Content>
    )
}
