import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Layout} from "./layout/layout";
import {Home} from "./pages/home/home";
import {Audience} from "./pages/audience/audience";
import {WayOfWorking} from "./pages/way-of-working/way-of-working";
import {AboutMe} from "./pages/about-me/about-me";
import {Rates} from "./pages/rates/rates";
import {Appointments} from "./pages/appointments/appointments";
import {Contact} from "./pages/contact/contact";
import ScrollToTop from "./utils/scrollToTop";

export function Router() {
    return <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="doelgroep" element={<Audience />} />
                <Route path="aanpak" element={<WayOfWorking />} />
                <Route path="over-mezelf" element={<AboutMe />} />
                <Route path="tarieven" element={<Rates />} />
                <Route path="maak-een-afspraak" element={<Appointments />} />
                <Route path="contact" element={<Contact />} />
            </Route>
        </Routes>
    </BrowserRouter>;
}
