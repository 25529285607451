import styled from "styled-components";

export const MePicture = styled.picture`
  width: 10rem;
  margin: 0 0 2rem 2rem;
  display: inline-block;
  float: right;
  
  img {
    width: 100%;
  }
`;
export const MePictureMobile = styled.picture`
  width: 15rem;
  margin: 1rem auto;
  display: inline-block;
  
  img {
    width: 100%;
  }
`;
