import React, { createContext, useContext, useMemo } from 'react'
import {useResize} from "../hooks/use-media";

const WAIT = 0;
export const RESIZE_DIRECTION = {
    Larger: 'larger',
    None: 'none',
    Smaller: 'smaller',
};

export const MEDIA_SIZES = {
    MiniPhone: 'mini-phone',
    Phone: 'phone',
    TabletPortrait: 'tablet-portrait',
    TabletLandscape: 'tablet-landscape',
    Desktop: 'desktop',
    BigDesktop: 'big-desktop',
};

export const MEDIA_SIZE_BREAKPOINTS = {
    [MEDIA_SIZES.MiniPhone]: [0, 459],
    [MEDIA_SIZES.Phone]: [460, 599],
    [MEDIA_SIZES.TabletPortrait]: [600, 899],
    [MEDIA_SIZES.TabletLandscape]: [900, 1199],
    [MEDIA_SIZES.Desktop]: [1200, 1799],
    [MEDIA_SIZES.BigDesktop]: [1800],
}

export const MediaSizeContext = createContext({
    screenSize: 0,
    previousScreenSize: 0,
    time: WAIT,
    amountResized: 0,
    direction: RESIZE_DIRECTION.None,
    velocity: 0,
    mediaSize: MEDIA_SIZES.Phone
});

export const MediaSizeProvider = ({ children }) => {
    const { screenSize, previousScreenSize, time } = useResize(WAIT);
    const amountResized = useMemo(() => (
        screenSize - previousScreenSize
    ), [screenSize, previousScreenSize])

    const direction = useMemo(() => {
        if (amountResized < 0) {
            return RESIZE_DIRECTION.Smaller;
        } else if (amountResized > 0) {
            return RESIZE_DIRECTION.Larger;
        }
        return RESIZE_DIRECTION.None;
    }, [amountResized]);

    const velocity = useMemo(() => (
        Math.abs(amountResized / time)
    ), [amountResized, time]);

    const mediaSize = useMemo(() => {
        for (let mapping in MEDIA_SIZE_BREAKPOINTS) {
            const sizes = MEDIA_SIZE_BREAKPOINTS[mapping];
            if(screenSize >= sizes[0]) {
                if(typeof sizes[1] === "undefined" || screenSize <= sizes[1]) {
                    return mapping;
                }
            }
        }
        return MEDIA_SIZES.Phone;
    }, [screenSize]);

    const value = useMemo(() => ({
        screenSize,
        previousScreenSize,
        time,
        amountResized,
        direction,
        velocity,
        mediaSize
    }), [
        screenSize,
        previousScreenSize,
        time,
        amountResized,
        direction,
        velocity,
        mediaSize
    ]);

    return (
        <MediaSizeContext.Provider value={value}>
            {children}
        </MediaSizeContext.Provider>
    );
}
export const useMediaSizeContext = () => useContext(MediaSizeContext)
