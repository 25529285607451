import {Router} from "./router";
import './app.scss';
import {ScrollProvider} from "./contexts/scroll";
import {MediaSizeProvider} from "./contexts/media-size";
import {HelmetProvider} from "react-helmet-async";

function App() {
    return (
        <HelmetProvider>
            <ScrollProvider>
                <MediaSizeProvider>
                    <Router/>
                </MediaSizeProvider>
            </ScrollProvider>
        </HelmetProvider>
    );
}

export default App;
