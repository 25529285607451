import {
    Anchor,
    Button,
    Content, ErrorMessage, FooterGoogleMaps,
    Form,
    FormButtons,
    Input,
    PageTitle,
    Paragraph,
    Section,
    Spacer, SuccessMessage,
    TextArea,
} from "../../styles/content";
import {useRef, useState} from "react";
import {MEDIA_SIZES, useMediaSizeContext} from "../../contexts/media-size";
import {Helmet} from "react-helmet-async";

export function Contact() {
    const {mediaSize} = useMediaSizeContext();

    const defaultState = {name: '', email: '', phone: '', topic: '', message: ''};
    const form = useRef();
    const [formState, setFormState] = useState(defaultState);
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();

    const handleChange = (e) => {
        setFormState({...formState, [e.target.name]: e.target.value});
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (formState.name && formState.email && formState.message) {
            const response = await fetch("/contact", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(formState)
            });
            const body = await response.json();
            if(body.success) {
                setErrorMessage(null);
                setSuccessMessage("Het formulier werd succesvol verstuurd.")
                setFormState(defaultState);
            } else {
                setSuccessMessage(null);
                setErrorMessage("Het formulier kon niet worden opgeladen, probeer het nog eens of je kan me ook altijd bereiken via telefoon of e-mail.");
            }
        }
    };

    const formWidth =
        mediaSize === MEDIA_SIZES.MiniPhone ? "90%"
            : mediaSize === MEDIA_SIZES.Phone ? "80%"
                : mediaSize === MEDIA_SIZES.TabletPortrait ? "75%"
                    : mediaSize === MEDIA_SIZES.TabletLandscape ? "75%"
                        : "50%";

    const mapHeight =
        mediaSize === MEDIA_SIZES.MiniPhone ? "280px"
            : mediaSize === MEDIA_SIZES.Phone ? "350px"
                : mediaSize === MEDIA_SIZES.TabletPortrait ? "350px"
                    : mediaSize === MEDIA_SIZES.TabletLandscape ? "520px"
                    : mediaSize === MEDIA_SIZES.Desktop ? "680px"
                        : "920px";

    return (
        <Content>
            <Helmet>
                <title>De Spiegel - Contact</title>
                <meta name="description"
                      content="Hier kan je al onze contact informatie terugvinden. Je kan ook een boodschap
                      achterlaten via het contactformulier, wij nemen daarna zo snel mogelijk contact met u op."/>
            </Helmet>
            <PageTitle>
                Contact
            </PageTitle>
            <Section>
                <Paragraph mb={2}>
                    Psychologiepraktijk De Spiegel
                </Paragraph>
                <Paragraph mb={2}>
                    Dorpsstraat 82<br/>
                    3620 Lanaken
                </Paragraph>
                <Paragraph>
                    <Anchor href="tel:+32493479844">0493 47 98 44</Anchor>
                </Paragraph>
                <Paragraph>
                    <Anchor href="mailto:psycholoog.tanja@gmail.com">psycholoog.tanja@gmail.com</Anchor>
                </Paragraph>
            </Section>
            <Section>
                <Paragraph mb={2}>
                    Je kan ook het contactformulier hieronder invullen.
                </Paragraph>
                <Form ref={form} w={formWidth} mHor={"auto"} onSubmit={handleFormSubmit}>
                    {errorMessage && <ErrorMessage mb="1rem" p="1rem">{errorMessage}</ErrorMessage>}
                    {successMessage && <SuccessMessage mb="1rem" p="1rem">{successMessage}</SuccessMessage>}
                    <Input name="name" required={true} value={formState.name} onChange={handleChange} placeholder={"Naam  *"}/>
                    <Input name="email" required={true} value={formState.email} onChange={handleChange} placeholder={"E-mailadres  *"}/>
                    <Input name="phone" value={formState.phone} onChange={handleChange} placeholder={"Telefoonnummer"}/>
                    <Input name="topic" value={formState.topic} onChange={handleChange} placeholder={"Onderwerp"}/>
                    <TextArea name="message" required={true} value={formState.message} onChange={handleChange} placeholder={"Bericht  *"} rows={4}/>
                    <FormButtons>
                        <Button type="submit">Verzenden</Button>
                    </FormButtons>
                </Form>
            </Section>
            <Spacer/>
            <FooterGoogleMaps>
                <iframe title="maps"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20137.9533901777!2d5.610203!3d50.882191000000006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0dd5e90dae70d%3A0x32c8c9847402646f!2sDorpsstraat%2082%2C%203620%20Lanaken!5e0!3m2!1sen!2sbe!4v1660909155019!5m2!1sen!2sbe"
                    width="100%" height={mapHeight} frameBorder="0" allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade" />
            </FooterGoogleMaps>
        </Content>
    )
}
