import styled, {css} from "styled-components";
import {
  forDesktopDown,
  forMiniPhoneOnly,
  forPhoneDown,
  forTabletLandscapeDown, forTabletPortraitDown,
  setMargins,
  setPaddings, setTextAlign,
  setWidth
} from "../mixins";
import UListIcon from "../icons/list-item-image.svg";

export const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: calc(920px + 2rem);
  ${forDesktopDown(css`
    padding-bottom: calc(680px + 2rem);
  `)}

  ${forTabletLandscapeDown(css`
    padding-bottom: calc(520px + 2rem);
  `)}

  ${forTabletPortraitDown(css`
    padding-bottom: calc(350px + 2rem);
  `)}
  ${forMiniPhoneOnly(css`
    padding-bottom: calc(280px + 2rem);
  `)}
`;

export const Spacer = styled.div`
  width: 10rem;
  margin: 1rem auto;
  height: 2px;
  background-color: var(--background-color);
`;

export const Section = styled.section`
  width: 70vw;
  margin: 1rem auto;
  
  ${forTabletLandscapeDown(css`
      width: 85vw;
  `)}
`;

export const SectionTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
`;

export const PageTitle = styled.h1`
  font-size: 2rem;
  color: var(--primary-color);
`;

export const StandoutLine = styled.span`
  display: block;
  padding: 0.6rem 0;
  font-size: 1.2rem;
`;

export const FooterPicture = styled.picture`
  width: 100%;
  height: 920px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  
  img {
   width: 100%; 
  }

  ${forDesktopDown(css`
    height: 680px;
  `)}

  ${forTabletLandscapeDown(css`
    height: 520px;
  `)}

  ${forTabletPortraitDown(css`
    height: 350px;
  `)}
  ${forMiniPhoneOnly(css`
    height: 280px;
  `)}
`;

export const FooterGoogleMaps = styled.div`
  margin: 1rem 0;
  width: 100%;
  height: 920px;
  position: absolute;
  bottom: 0;
  
  ${forDesktopDown(css`
    height: 680px;
  `)}

  ${forTabletLandscapeDown(css`
    height: 520px;
  `)}

  ${forTabletPortraitDown(css`
    height: 350px;
  `)}

  ${forMiniPhoneOnly(css`
    height: 280px;
  `)}
`;

export const Paragraph = styled.p`
  ${setMargins}
  ${setTextAlign}
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({dir}) => dir || 'row'};
  justify-content: ${({jc}) => jc || 'center'};
  align-items: ${({ai}) => ai || 'center'};
  ${setMargins}
  ${setPaddings}
  
  > * {
    flex: 1 1 auto;
  }
`;

export const UList = styled.ul`
  list-style: none;
  text-align: left;
  ${setWidth}
  ${setMargins}
  ${setPaddings}
  
  > li {
    display: flex;
    align-items: center;
    padding: 0.4rem 0;
    
    &:before {
      content:'';
      color: green;
      display:inline-block;
      height:1em;
      width:1em;
      background-image:url(${UListIcon});
      background-size:contain;
      background-repeat:no-repeat;
      padding-left: 1rem;
      vertical-align: middle;
    }
  }
`;

export const ListItem = styled.li`
  &:before {
    content: ''
  }
`;

export const Anchor = styled.a`
  color: var(--primary-color);
  text-decoration: none;
`;

export const Button = styled.button`
  background-color: var(--primary-color);
  color: var(--white-color);
  border: 0;
  font-size: inherit;
  padding: 0.4rem 2rem;
  cursor: pointer;
  
  &:active,
  &:hover {
    background-color: var(--primary-highlight);
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${setWidth}
  ${setMargins}
  ${setPaddings}
`;

export const FormButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  
  ${forPhoneDown(css`
    flex-direction: column;
  `)}
`;

export const Input = styled.input`
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  border: 1px solid var(--primary-color);
  padding: 0.4rem 1rem;
  font-size: 1rem;
  line-height: 2em;
  margin-bottom: 0.4rem;
`;

export const TextArea = styled.textarea`
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  border: 1px solid var(--primary-color);
  padding: 0.4rem 1rem;
  font-size: 1rem;
  line-height: 2em;
  margin-bottom: 0.4rem;
`;

export const FormMessage = styled.div`
  color: #ba3939;
  background: #ffe0e0;
  border: 1px solid #a33a3a;
  font-size: 0.6rem;
  line-height: 1.2rem;
  font-style: italic;
  ${setMargins}
  ${setPaddings}
`;

export const ErrorMessage = styled(FormMessage)`
  color: #ba3939;
  background: #ffe0e0;
  border: 1px solid #a33a3a;
`;

export const SuccessMessage = styled(FormMessage)`
  color: #2b7515;
  background: #ecffd6;
  border: 1px solid #617c42;
`;
