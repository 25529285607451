import styled, {css} from "styled-components";
import Logo from "../images/logo.svg";
import {forDesktopDown, forMiniPhoneOnly, forPhoneDown, forTabletLandscapeDown, forTabletPortraitDown} from "../mixins";

export const LayoutContainer = styled.div`
  background: #fff;
  min-height: 100vh;
  position: relative;
  
  &.not-home {
    padding-top: 7rem;
  }
`;

export const AppHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  min-height: 20rem;
  margin-top: 3rem;

  ${forTabletLandscapeDown(css`
    margin-top: 5rem;
  `)}

  ${forMiniPhoneOnly(css`
    min-height: 16rem;
  `)}
`;

export const FixedHeader = styled.header`
  width: 100vw;
  background-color: #fff;
  position: fixed;
  top: 0;
  height: 5rem;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--background-color);

  :not(.fixed) {
    height: 3rem;
  }

  ${forTabletLandscapeDown(css`
    padding: 0.5rem 1rem;

    :not(.fixed) {
      height: 5rem;
    }
  `)}

  ${forMiniPhoneOnly(css`
    height: 4rem;

    :not(.fixed) {
      height: 4rem;
    }
  `)}
`;

export const Wrapper = styled.section`
  flex: 1 1 auto;
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const FixedWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  color: var(--primary-color);
`;

export const AppLogo = styled.div`
  background-image: url(${Logo});
  background-position: center center;
  background-size: cover;
  height: calc(8rem * 0.9);
  width: 8rem;
  ${forPhoneDown(css`
    margin-bottom: 1rem;
  `)}
`;

export const FixedAppLogo = styled.div`
  background-image: url(${Logo});
  background-position: center center;
  background-size: cover;
  height: 4rem;
  width: calc(4rem * 1.1);
  display: inline-block;

  ${forMiniPhoneOnly(css`
    display: none;
  `)}
`;

export const FixedTitles = styled.div`
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${forDesktopDown(css`
    margin-left: 0.8rem;
  `)}
  ${forTabletLandscapeDown(css`
    margin-left: 0.4rem;
  `)}
  ${forTabletPortraitDown(css`
    margin-left: 1.4rem;
  `)}
  ${forPhoneDown(css`
    margin-left: 0.6rem;
  `)}
  h1 {
    font-size: 1.2rem;
    line-height: 1.8rem;

    ${forTabletLandscapeDown(css`
      font-size: 1rem;
      line-height: 1.4rem;
    `)}

    ${forTabletPortraitDown(css`
      font-size: 1.4rem;
      line-height: 2rem;
    `)}

    ${forPhoneDown(css`
      font-size: 1rem;
      line-height: 2rem;
    `)}
  }

  h2 {
    font-size: 1rem;
    line-height: 1.6rem;

    ${forTabletLandscapeDown(css`
      font-size: 0.9rem;
      line-height: 1.4rem;
    `)}

    ${forTabletPortraitDown(css`
      font-size: 1rem;
      line-height: 1.4rem;
    `)}


    ${forPhoneDown(css`
      font-size: 0.8rem;
      line-height: 1.4rem;
    `)}
  }
`;

export const FixedNavigation = styled.nav`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  > div {
    display: block;
  }

  > a {
    display: block;
    padding: 0;
    color: var(--primary-color);
    text-decoration: none;

    &:hover,
    &:active,
    &.active {
      border-bottom: 1px solid var(--primary-color);
    }
  }

  ${forDesktopDown(css`
    font-size: 0.8rem;
  `)}

  ${forTabletLandscapeDown(css`
    font-size: 0.65rem;
  `)}
`;

export const MobileNavigationWrapper = styled.div`
  position: fixed;
  right: 1.6rem;
  top: 3.9rem;
  display: none;
  border: 4px solid var(--primary-color);
  width: 19rem;

  ${forPhoneDown(css`
    border-width: 2px;
  `)}
  ${forMiniPhoneOnly(css`
    width: 100vw;
    right: 0;
    left: 0;
  `)}
  &.visible {
    display: block;
  }
`;

export const MobileNavigation = styled.nav`
  flex: 1 1 auto;
  display: flex;
  padding: 0;
  flex-direction: column;
  background-color: var(--white-color);

  > div {
    display: none;
  }

  > a {
    display: block;
    padding: 0.5rem;
    color: var(--primary-color);
    text-decoration: none;
    border-bottom: 2px solid var(--primary-color);

    ${forPhoneDown(css`
      border-width: 1px;
    `)}
    &:hover,
    &:active,
    &.active {
      text-decoration: none;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
`;

export const MobileNavButton = styled.svg`
  flex: 0 0 3rem;
  padding: 0.5rem 0;
  color: var(--primary-color);
  cursor: pointer;
`;

export const LinkSpacer = styled.div`
  &:after {
    content: '-';
    margin: 0 0.3rem;
  }
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2rem;
  background-color: var(--background-color);
  text-align: center;
  line-height: 2rem;
`;
