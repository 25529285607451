import {Content, Flex, ListItem, PageTitle, Paragraph, Section, Spacer, UList} from "../../styles/content";
import {MEDIA_SIZES, useMediaSizeContext} from "../../contexts/media-size";
import {Helmet} from "react-helmet-async";
import {FooterImage} from "../../components/footer-image";

import FooterImageAvif2160 from "../../images/footer-audience-2160.avif";
import FooterImageAvif1440 from "../../images/footer-audience-1440.avif";
import FooterImageAvif720 from "../../images/footer-audience-720.avif";
import FooterImageAvif480 from "../../images/footer-audience-480.avif";
import FooterImageWebp2160 from "../../images/footer-audience-2160.webp";
import FooterImageWebp1440 from "../../images/footer-audience-1440.webp";
import FooterImageWebp720 from "../../images/footer-audience-720.webp";
import FooterImageWebp480 from "../../images/footer-audience-480.webp";
import FooterImageJpg2160 from "../../images/footer-audience-2160.jpg";
import FooterImageJpg1440 from "../../images/footer-audience-1440.jpg";
import FooterImageJpg720 from "../../images/footer-audience-720.jpg";
import FooterImageJpg480 from "../../images/footer-audience-480.jpg";

export function Audience() {
    const {mediaSize} = useMediaSizeContext();
    const isSmallSize = [MEDIA_SIZES.MiniPhone, MEDIA_SIZES.Phone, MEDIA_SIZES.TabletPortrait].includes(mediaSize);
    const isPhoneSize = [MEDIA_SIZES.MiniPhone, MEDIA_SIZES.Phone].includes(mediaSize);
    return (
        <Content>
            <Helmet>
                <title>De Spiegel - Doelgroep</title>
                <meta name="description"
                      content="Hier vindt je een opsomming terug van de verschillende thema's waarrond er gewerkt kan
                  worden. Er komt een grote variatie aan bod, gaande van communicatieproblemen in je relatie
                  tot het vinden van een juiste work-life balance."/>
            </Helmet>
            <PageTitle>
                Doelgroep
            </PageTitle>
            <Section>
                <Paragraph mb={1}>
                    Hieronder vind je enkele voorbeelden van thema’s waar ik rond werk, maar eigenlijk ben je met eender
                    welke hulpvraag welkom:
                </Paragraph>
                <Flex jc="space-around" ai="flex-start" dir={isSmallSize ? "column" : "row"} mt={1}>
                    <UList pl={isPhoneSize ? "10%" : isSmallSize ? "33%" : "25%"} pr={1}>
                        <ListItem>Depressieve gevoelens</ListItem>
                        <ListItem>Angstklachten</ListItem>
                        <ListItem>Stress / piekeren</ListItem>
                        <ListItem>Eetproblemen</ListItem>
                        <ListItem>Laag zelfbeeld, onzekerheid,</ListItem>
                        <ListItem>Assertiviteit </ListItem>
                    </UList>
                    <UList pl={isPhoneSize ? "10%" : isSmallSize ? "33%" : 0}>
                        <ListItem>Slaapproblemen</ListItem>
                        <ListItem>Perfectionisme</ListItem>
                        <ListItem>Communicatieproblemen in relaties</ListItem>
                        <ListItem>Omgang en relaties met anderen</ListItem>
                        <ListItem>...</ListItem>
                    </UList>
                </Flex>
            </Section>
            <Section>
                <Paragraph>
                    Verder is psychologische begeleiding niet altijd om klachten te verminderen, maar ook om algemene
                    psychologische gezondheid te verhogen!
                </Paragraph>
                <Paragraph>
                    Aangezien alle mogelijke thema’s onmogelijk op te sommen zijn, is het best mogelijk dat je jezelf
                    niet herkent in bovenstaande lijst. Misschien is het ook moeilijk om een ‘onderwerp’ te plakken op
                    de klachten waar jij mee zit. Daarom gaan we samen in dialoog om te bespreken waar jou hulpvraag zit
                    en hoe ik daar een rol in kan spelen.
                </Paragraph>
            </Section>
            <Spacer/>
            <Section>
                <Paragraph>
                    Voor velen maakt een job ook een groot deel uit van het dagelijkse leven. Het is dan ook niet
                    ondenkbaar dat je werkleven een rol speelt in je algemeen welzijn. Thema’s die hierbij kunnen
                    voorkomen, zijn:
                </Paragraph>
                <Flex jc="space-around" ai="flex-start" dir={isSmallSize ? "column" : "row"} mb={2} mt={2}>
                    <UList pl={isPhoneSize ? "10%" : isSmallSize ? "33%" : "25%"} pr={1}>
                        <ListItem>Burn-out</ListItem>
                        <ListItem>Motivatieproblemen</ListItem>
                        <ListItem>Botsen met bedrijfscultuur</ListItem>
                        <ListItem>Bore-out</ListItem>
                    </UList>
                    <UList pl={isPhoneSize ? "10%" : isSmallSize ? "33%" : 0}>
                        <ListItem>Loopbaankeuzes</ListItem>
                        <ListItem>Werkdruk</ListItem>
                        <ListItem>Work-life balance</ListItem>
                        <ListItem>...</ListItem>
                    </UList>
                </Flex>
            </Section>
            <Section>
                <Paragraph>
                    Hierin sta jij in je professioneel functioneren centraal. Het doel is om jouw welzijn te waarborgen,
                    maar ook om gemotiveerd in het werkveld te kunnen staan. Ook hierin kan ik je de ondersteuning
                    bieden die je nodig hebt.
                </Paragraph>
            </Section>
            <Spacer/>
            <FooterImage avifImages={{xl: FooterImageAvif2160, lg: FooterImageAvif1440, sm: FooterImageAvif720, xs: FooterImageAvif480}}
                         webpImages={{xl: FooterImageWebp2160, lg: FooterImageWebp1440, sm: FooterImageWebp720, xs: FooterImageWebp480}}
                         jpgImages={{xl: FooterImageJpg2160, lg: FooterImageJpg1440, sm: FooterImageJpg720, xs: FooterImageJpg480}}
            />
        </Content>
    )
}
