import {Content,  PageTitle, Paragraph, Section, Spacer} from "../../styles/content";
import {Helmet} from "react-helmet-async";
import {FooterImage} from "../../components/footer-image";

import FooterImageAvif2160 from "../../images/footer-way-of-working-2160.avif";
import FooterImageAvif1440 from "../../images/footer-way-of-working-1440.avif";
import FooterImageAvif720 from "../../images/footer-way-of-working-720.avif";
import FooterImageAvif480 from "../../images/footer-way-of-working-480.avif";
import FooterImageWebp2160 from "../../images/footer-way-of-working-2160.webp";
import FooterImageWebp1440 from "../../images/footer-way-of-working-1440.webp";
import FooterImageWebp720 from "../../images/footer-way-of-working-720.webp";
import FooterImageWebp480 from "../../images/footer-way-of-working-480.webp";
import FooterImageJpg2160 from "../../images/footer-way-of-working-2160.jpg";
import FooterImageJpg1440 from "../../images/footer-way-of-working-1440.jpg";
import FooterImageJpg720 from "../../images/footer-way-of-working-720.jpg";
import FooterImageJpg480 from "../../images/footer-way-of-working-480.jpg";

export function WayOfWorking() {
    return (
        <Content>
            <Helmet>
                <title>De Spiegel - Aanpak</title>
                <meta name="description"
                      content="Hier leggen we uit welke werkwijze we hanteren. Hiermee kan  u zich goed voorbereiden
                      op de eerste sessies." />
            </Helmet>
            <PageTitle>
                Aanpak
            </PageTitle>
            <Section>
                <Paragraph mb={1}>
                    Tijdens een eerste gesprek (intakegesprek) worden de moeilijkheden waar je tegenaan loopt in kaart
                    gebracht. Zo krijgen we een duidelijk beeld van de hulpvraag waar jij mee zit. Na dit gesprek is het
                    belangrijk dat je een klik ervaart met je psycholoog. Dit is nodig om een traject op te starten
                    waarin vertrouwen een belangrijke rol speelt. De veiligheid die je ervaart tijdens de sessie
                    stimuleert mee de verandering en groei waar je naar op zoek bent.
                </Paragraph>
            </Section>
            <Section>
                <Paragraph>
                    Samen gaan we op zoek naar wat voor jou helpend en waardevol is. De focus ligt volledig op jou: ik
                    wil jou ruimte bieden om je eigen verhaal te brengen. Ik houd hier de rode draad in om samen tot
                    herkenbare thema’s te komen. Ikzelf kom niet met een pasklare oplossing, maar door jezelf te leren
                    kennen en aan jezelf te werken tijdens de sessies, zoeken we samen naar een oplossing voor jou.
                    Enkele vragen waar we een antwoord op willen zoeken, kunnen zijn: Waar loop je op vast? Welke
                    patronen spelen er? Hoe kan je het anders aanpakken? Hoe kan je in beweging komen?
                    Zo is er dus niet één juiste aanpak en wordt de frequentie en duur van de begeleiding steeds in
                    overleg bepaald. Een eenmalig gesprek kan ook tot de mogelijkheden behoren.
                </Paragraph>
            </Section>
            <Spacer />
            <FooterImage avifImages={{xl: FooterImageAvif2160, lg: FooterImageAvif1440, sm: FooterImageAvif720, xs: FooterImageAvif480}}
                         webpImages={{xl: FooterImageWebp2160, lg: FooterImageWebp1440, sm: FooterImageWebp720, xs: FooterImageWebp480}}
                         jpgImages={{xl: FooterImageJpg2160, lg: FooterImageJpg1440, sm: FooterImageJpg720, xs: FooterImageJpg480}}
            />
        </Content>
    )
}
