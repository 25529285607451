import {css} from "styled-components";

export const forMiniPhoneOnly = (content)  => css`
  @media (max-width: 459px) { 
    ${content}; 
  }
`;

export const forPhoneUp = (content)  => css`
  @media (min-width: 460px) { 
    ${content}; 
  }
`;

export const forTabletPortraitUp = (content)  => css`
  @media (min-width: 600px) { 
    ${content}; 
  }
`;

export const forTabletLandscapeUp = (content)  => css`
  @media (min-width: 900px) { 
    ${content}; 
  }
`;

export const forDesktopUp = (content)  => css`
  @media (min-width: 1200px) {
    ${content};
  }
`;

export const forBigDesktopUp = (content)  => css`
  @media (min-width: 1800px) {
    ${content};
  }
`;

export const forPhoneDown = (content)  => css`
  @media (max-width: 599px) { 
    ${content}; 
  }
`;

export const forTabletPortraitDown = (content)  => css`
  @media (max-width: 899px) { 
    ${content}; 
  }
`;

export const forTabletLandscapeDown = (content)  => css`
  @media (max-width: 1199px) { 
    ${content}; 
  }
`;

export const forDesktopDown = (content)  => css`
  @media (max-width: 1799px) {
    ${content};
  }
`;

export const setWidth = css`
    width: ${({w}) => size(w, 'auto')};
`;

export const setMargins = css`
    margin: ${({m, mt, mr, mb, ml, mVer, mHor}) => `${size(mt, mVer, m, 0)} ${size(mr, mHor, m, 0)} ${size(mb, mVer, m, 0)} ${size(ml, mHor, m, 0)}`};
`;

export const setTextAlign = css`
    text-align: ${({ta}) => `${ta || "center"}`};
`;

export const setPaddings = css`
  padding: ${({p, pt, pr, pb, pl, pVer, pHor}) => `${size(pt, pVer, p, 0)} ${size(pr, pHor, p, 0)} ${size(pb, pVer, p, 0)} ${size(pl, pHor, p, 0)}`};
`;

export const size = (s, ...alts) => {
    if(typeof s === 'undefined'){
        if(alts.length > 0) {
            return size(...alts);
        }
        return '0';
    }
    if(typeof s === 'number') {
        return `${s}rem`;
    }
    return s;
}
