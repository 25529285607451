import {FooterPicture} from "../styles/content";

export function FooterImage({avifImages, webpImages, jpgImages}) {
    return <FooterPicture>
        <source srcSet={avifImages.xl} type="image/avif" media="(min-width: 1440px)" />
        <source srcSet={avifImages.lg} type="image/avif" media="(min-width: 720px)" />
        <source srcSet={avifImages.sm} type="image/avif" media="(min-width: 480px)" />
        <source srcSet={avifImages.xs} type="image/avif" />
        <source srcSet={webpImages.xl} type="image/webp" media="(min-width: 1440px)" />
        <source srcSet={webpImages.lg} type="image/webp" media="(min-width: 720px)" />
        <source srcSet={webpImages.sm} type="image/webp" media="(min-width: 480px)" />
        <source srcSet={webpImages.xs} type="image/webp" />
        <source srcSet={jpgImages.xl} media="(min-width: 1440px)" />
        <source srcSet={jpgImages.lg} media="(min-width: 720px)" />
        <source srcSet={jpgImages.sm} media="(min-width: 480px)" />
        <img src={jpgImages.xs} alt="Footer" loading="lazy" decoding="async" />
    </FooterPicture>;
}
