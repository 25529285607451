import {Content, Section, Spacer, StandoutLine} from "../../styles/content";
import {Helmet} from "react-helmet-async";
import {FooterImage} from "../../components/footer-image";

import FooterImageAvif2160 from "../../images/footer-welcome-2160.avif";
import FooterImageAvif1440 from "../../images/footer-welcome-1440.avif";
import FooterImageAvif720 from "../../images/footer-welcome-720.avif";
import FooterImageAvif480 from "../../images/footer-welcome-480.avif";
import FooterImageWebp2160 from "../../images/footer-welcome-2160.webp";
import FooterImageWebp1440 from "../../images/footer-welcome-1440.webp";
import FooterImageWebp720 from "../../images/footer-welcome-720.webp";
import FooterImageWebp480 from "../../images/footer-welcome-480.webp";
import FooterImageJpg2160 from "../../images/footer-welcome-2160.jpg";
import FooterImageJpg1440 from "../../images/footer-welcome-1440.jpg";
import FooterImageJpg720 from "../../images/footer-welcome-720.jpg";
import FooterImageJpg480 from "../../images/footer-welcome-480.jpg";

export function Home() {
    return (
        <Content>
            <Helmet>
                <meta
                    name="description"
                    content="Word terug expert over je eigen leven"
                />
            </Helmet>
            <Spacer />
            <Section>
                <StandoutLine>
                Heb je het gevoel dat het allemaal even te veel is?
                </StandoutLine>
                <StandoutLine>
                    Dat je vastloopt op dezelfde thema’s?
                </StandoutLine>
                <StandoutLine>
                    Of zit je in de knoop met jezelf?
                </StandoutLine>
            </Section>
            <Section>
                Dan kan psychologische begeleiding hetgeen zijn dat je weer in beweging zet.
                Ook als je jezelf beter wilt leren kennen, kan je bij mij terecht.
                We gaan samen op zoek door je een spiegel voor te houden en vragen te stellen zodat je terug de expert over je eigen leven kan worden.
                Ik zal je hierin zo goed mogelijk begeleiden door middel van begeleidende gesprekken en sessies.
            </Section>
            <Spacer />
            <FooterImage avifImages={{xl: FooterImageAvif2160, lg: FooterImageAvif1440, sm: FooterImageAvif720, xs: FooterImageAvif480}}
                         webpImages={{xl: FooterImageWebp2160, lg: FooterImageWebp1440, sm: FooterImageWebp720, xs: FooterImageWebp480}}
                         jpgImages={{xl: FooterImageJpg2160, lg: FooterImageJpg1440, sm: FooterImageJpg720, xs: FooterImageJpg480}}
            />
        </Content>
    )
}
